import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import Item from 'components/Items/Item';
import ItemsName from 'components/Items/ItemsName';
import {
  getMainBestItems,
  getLatestMainItems,
  setProductListForInfiniteScroll,
} from 'store/mall';

export default function Items({
  categoryName,
  name,
  shape,
  xs = 12,
  tabIndex,
}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state?.auth.user);

  const productList = useSelector(
    state => state?.mall.productListForInfiniteScroll,
  );
  const status = useSelector(state => state?.mall.status);
  const currentCategoryCode = useSelector(state => state?.mall.currentCategory);
  const bestProducts = useSelector(state => state?.mall.productsBest);
  const latestProducts = useSelector(
    state => state?.mall.productsLatest,
  );
  const currentHomeTab = useSelector(state => state?.app.activeTab.home);
  const sortBy = useSelector(state => state?.app.sortBy);
  const [page, setPage] = useState(1);


  useEffect(() => {
    (() => {
      if (user) {
        // loadProductList();

        // 'name' Is From 'AssortedPaenl.jsx'
        // Home Page "베스트" List
        if (name === '베스트') {
          dispatch(
            getMainBestItems({
              page: 1,
              size: 20,
              // categoryCode: currentCategoryCode,
              bestType: 'DAY',
            }),
          );
        }

        // Home Page "신제품순" List
        if (name === '신제품순') {
          dispatch(
              getLatestMainItems({
              // categoryCode: currentCategoryCode,
              page: 1,
              count: 20,
              viewStandard: 'update',
              viewDirection: 'DESC',
            }),
          );
        }
      }
    })();
  }, [currentCategoryCode]);

  useEffect(() => {
    loadProductList(page);
  }, [sortBy, page]);

  useEffect(() => {
    if (categoryName === 'best') addProductIntoList(bestProducts);
    if (categoryName === 'savingsPoint') addProductIntoList(latestProducts);
  }, [bestProducts, latestProducts]);

  const loadProductList = p => {
    const dataForBest = {
      page: p,
      size: 20,
      categoryCode: currentCategoryCode === 'all' ? null : currentCategoryCode,
      bestType: sortBy.best,
    };

    const dataForLatestProducts = {
      categoryCode: currentCategoryCode === 'all' ? null : currentCategoryCode,
      page: p,
      count: 20,
      viewStandard: sortBy.latest,
      viewDirection: 'DESC',
    };
    // 'categoryName' is from 'StorePanel'
    if (categoryName === 'best') {
      dispatch(getMainBestItems(dataForBest));
    }
    if (categoryName === 'savingsPoint') {
      dispatch(getLatestMainItems(dataForLatestProducts));
    }
  };

  const addProductIntoList = items => {
    const same = items.find(item => {
      const result = productList?.find(data => data.itemId === item.itemId);
      return result;
    });

    if (same) return;
    const newList = [...productList, ...items];
    dispatch(setProductListForInfiniteScroll(newList));
  };

  const changePage = () => {
    setPage(prev => prev + 1);
  };

  return (
    <Box m={2}>
      {name !== '베스트' && name !== '신제품순' &&
          <ItemsName name={name} tabIndex={tabIndex} />
      }
      {(currentHomeTab === 1 || currentHomeTab === 2) && (
        <>
          {status === 'loading' && (
            <Box
              sx={{
                pt: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <InfiniteScroll
            dataLength={productList?.length}
            next={changePage}
            hasMore={
              currentHomeTab === 1
                ? bestProducts?.length !== 0
                : latestProducts?.length !== 0
            }
            // endMessage={
            //   status === 'succeeded' && (
            //     <Typography align="center">목록의 마지막입니다</Typography>
            //   )
            // }
            loader={<Typography align="center">상품을 불러오는 중</Typography>}
          >
            <Grid container>
              {/* Home Tab */}
              {/* Home Tab - "베스트" */}
              {currentHomeTab === 1 &&
                productList?.map((product, index) => (
                  <React.Fragment key={product?.itemId * index}>
                    <Grid item xs={xs} mb={2}>
                      <Item shape={shape} product={product} />
                    </Grid>
                  </React.Fragment>
                ))}
              {/* Home Tab - "알뜰적립" */}
              {currentHomeTab === 2 &&
                productList?.map((product, index) => (
                  <React.Fragment key={product?.itemId * index}>
                    <Grid item xs={xs} mb={2}>
                      <Item shape={shape} product={product} />
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
          </InfiniteScroll>
        </>
      )}
      <Grid container>
        {/* Home Tab Sub Category  */}
        {/* currentHomeTab이 0일 때(스토어홈) */}
        {name === '베스트' &&
          bestProducts?.map(product => (
            <React.Fragment key={product?.itemId}>
              <Grid item xs={xs} mb={2}>
                <Item shape={shape} product={product} />
              </Grid>
            </React.Fragment>
          ))}
        {name === '신제품순' &&
          latestProducts?.map(product => (
            <React.Fragment key={product?.itemId}>
              <Grid item xs={xs} mb={2}>
                <Item shape={shape} product={product} />
              </Grid>
            </React.Fragment>
          ))}
        {/* end - Home Page List */}
      </Grid>
    </Box>
  );
}
